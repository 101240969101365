import React, { useMemo } from "react";

import { MediaManagerFile } from "./components";
import { MediaManagerContentProps } from "./types";
import { UploadStatus } from "../../types";
import { Plus } from "react-feather";

const MediaManagerContent: React.FC<MediaManagerContentProps> = ({
  files,
  multiple,
  setFiles,
  onFileSelect,
  status,
  setStatus,
  activeFile,
  selectedFiles,
  setSelectedFiles,
}) => {
  const handleDeleteFile = (file: string) => {
    const index = files.indexOf(file);

    if (index === -1) {
      return;
    }

    setFiles([...files.slice(0, index), ...files.slice(index + 1)]);
  };

  const statusClassName = useMemo(() => {
    if (status === null) {
      return null;
    }

    switch (status) {
      case UploadStatus.PENDING:
        return "fa-spin fa-sync";
      case UploadStatus.SUCCESS:
        return "fa-check-circle";
      case UploadStatus.FAILED:
        return "fa-times-circle";
    }
  }, [status]);

  const handleAddAllSelectedClick = () => {
    onFileSelect(selectedFiles);
  };

  return (
    <div className="filemgr-content">
      <div className="filemgr-content-header">
        <h4 className="mg-b-0 mg-r-30">Sadržaj mape</h4>
        {multiple && (
          <div className="d-inline-flex">
            <div className="">
              <button
                className="btn btn-xs btn-block btn-white"
                disabled={selectedFiles.length === 0}
                onClick={handleAddAllSelectedClick}
              >
                Dodaj odabrano <Plus />
              </button>
            </div>
            <div className="custom-control custom-switch mg-l-15 mg-b-0 mg-t-7">
              <input
                type="checkbox"
                className="custom-control-input"
                id="multiselect"
              />
              <label className="custom-control-label" htmlFor="multiselect">
                Označi sve
              </label>
            </div>
          </div>
        )}
      </div>
      <div className="filemgr-content-body">
        <div className="pd-20 pd-lg-25 pd-xl-30">
          {status !== null && (
            <>
              <label className="d-block tx-medium tx-10 tx-uppercase tx-sans tx-spacing-1 tx-color-03 mg-b-15">
                Status uploada
              </label>
              <div className="row row-xs">
                <div
                  className="col-sm-12 col-lg-12 col-xl-12 mg-b-10"
                  onClick={() => setStatus(null)}
                  style={{ cursor: "pointer" }}
                >
                  <div className="media media-folder">
                    <i className={`fas pd-5 ${statusClassName}`}></i>
                    <div className="media-body">
                      <h6>
                        <a className="link-02">
                          {activeFile && activeFile.name}
                        </a>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          <hr className="mg-y-20 bd-0" />
          <label className="d-block tx-medium tx-10 tx-uppercase tx-sans tx-spacing-1 tx-color-03 mg-b-15">
            Datoteke
          </label>
          <div className="row row-xs">
            {files.map((file, index) => (
              <MediaManagerFile
                file={file}
                multiple={multiple}
                onDelete={() => handleDeleteFile(file)}
                onSelect={() => onFileSelect(file)}
                key={`file_${index}`}
                selectedFiles={selectedFiles}
                setSelectedFiles={setSelectedFiles}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MediaManagerContent;
