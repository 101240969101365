import { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Plus } from "react-feather";
import { v4 as uuidv4 } from "uuid";
import Datetime from "react-datetime";

import { FormFieldOption, FormFieldType } from "src/services/forms";
import { TextField } from "src/ui/components/TextField";

import { FormFieldAddProps } from "./types";

const FormFieldAdd: React.FC<FormFieldAddProps> = ({
  formFieldOption,
  onAdd,
}) => {
  const [title, setTitle] = useState("");
  const [error, setError] = useState(false);
  const [options, setOptions] = useState<FormFieldOption[]>([]);
  const [dateOptions, setDateOptions] = useState<FormFieldOption[]>([
    { id: uuidv4(), value: "", dependencyId: "" },
    { id: uuidv4(), value: "", dependencyId: "" },
  ]);

  const handleAddClicked = () => {
    if (!title) {
      setError(true);
      return;
    }

    onAdd(
      formFieldOption.type,
      title,
      formFieldOption.type === FormFieldType.RangeCalendar
        ? dateOptions
        : options
    );
    setTitle("");
    setOptions([]);
    setError(false);
    setDateOptions([
      { id: uuidv4(), value: "", dependencyId: "" },
      { id: uuidv4(), value: "", dependencyId: "" },
    ]);
  };

  const handleAddOptionClick = () => {
    if (formFieldOption.type === FormFieldType.RangeCalendar) {
      setDateOptions([
        ...dateOptions,
        { id: uuidv4(), value: "", dependencyId: "" },
        { id: uuidv4(), value: "", dependencyId: "" },
      ]);

      return;
    }

    setOptions([...options, { value: "", id: uuidv4(), dependencyId: "" }]);
  };

  const handleOptionValueChange = (index: number) => (e: any) => {
    setOptions((old) =>
      old.map((option, i) =>
        i === index ? { ...option, value: e.target.value } : option
      )
    );
  };

  const handleDateOptionChange = (index: number) => (value: any) => {
    setDateOptions((old) =>
      old.map((option, i) =>
        i === index
          ? { ...option, value: value ? value.format("YYYY-MM-DD") : "" }
          : option
      )
    );
  };

  return (
    <Row className="mg-b-10">
      <Col xs={9}>
        <div className="pd-10" style={{ border: "1px dotted #333" }}>
          <TextField
            label={formFieldOption.title}
            placeholder="Upiši naziv"
            onChange={(e) => setTitle(e.target.value)}
            value={title}
            hasError={error}
          />
          {formFieldOption.hasOptions && (
            <Button
              variant="outline-secondary"
              size="sm"
              onClick={handleAddOptionClick}
            >
              <Plus />
            </Button>
          )}
          {formFieldOption.type !== FormFieldType.RangeCalendar &&
            options.map((optionValue, optionIndex) => (
              <TextField
                onChange={handleOptionValueChange(optionIndex)}
                key={`option_value_${optionIndex}`}
                value={optionValue.value}
              />
            ))}
          {formFieldOption.type === FormFieldType.RangeCalendar &&
            dateOptions.map((optionValue, optionIndex) => (
              <Datetime
                dateFormat="DD.MM.YYYY."
                timeFormat="HH:mm"
                onChange={handleDateOptionChange(optionIndex)}
                value={
                  dateOptions[optionIndex].value
                    ? new Date(optionValue.value)
                    : undefined
                }
                inputProps={{
                  placeholder: optionIndex % 2 === 0 ? "Od" : "Do",
                }}
              />
            ))}
        </div>
      </Col>
      <Col xs={3}>
        <Button variant="outline-secondary" onClick={handleAddClicked}>
          <Plus /> Dodaj
        </Button>
      </Col>
    </Row>
  );
};

export default FormFieldAdd;
