export const initialArticle = {
  id: null,
  active_from: "",
  active_to: "",
  active: false,
  contents: [],
  category_ids: [],
  main_image: null,
  main_image_signature: "",
  author: "",
  password: "",
  locked: false,
  template_id: null,
  form_id: null,
  images: [],
};
