import { useState } from "react";
import { Folder, FolderPlus, CornerLeftUp, Upload } from "react-feather";
import { createDirectory } from "src/services/storage";
import { NewDirectory } from "./components";

import { MediaManagerSidebarProps } from "./types";

const MediaManagerSidebar: React.FC<MediaManagerSidebarProps> = ({
  directories,
  setDirectories,
  currentPath,
  setCurrentPath,
  onNewFileClick,
}) => {
  const [showNewDirectory, setShowNewDirectory] = useState(false);

  const handleDirectoryClicked = (path: string) => (e: any) => {
    e.preventDefault();

    setCurrentPath((old) => path.split("/"));
  };

  const handleBackClicked = (e: any) => {
    e.preventDefault();

    setCurrentPath((old) => [...old.slice(0, -1)]);
  };

  const handleNewFolderClicked = () => {
    setShowNewDirectory(true);
  };

  const handleNewDirectorySave = (name: string) => {
    const newDir = `${currentPath.join("/")}/${name}`;

    createDirectory(newDir);
    setDirectories([...directories, newDir]);
    setShowNewDirectory(false);
  };

  return (
    <div className="filemgr-sidebar">
      <div className="filemgr-sidebar-header">
        {/* <Dropdown>
          <Dropdown.Toggle size="sm" variant="white">
            Novo
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={handleNewFolderClicked}>
              <FolderPlus /> Direktorij
            </Dropdown.Item>
            <Dropdown.Item onClick={onNewFileClick}>
              <File /> Datoteka
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown> */}
        <div className="dropdown dropdown-icon flex-fill mg-l-10">
          <button
            onClick={handleNewFolderClicked}
            className="btn btn-xs btn-block btn-white"
          >
            Mapa <FolderPlus />
          </button>
        </div>
        <div className="dropdown dropdown-icon flex-fill mg-l-10">
          <button
            onClick={onNewFileClick}
            className="btn btn-xs btn-block btn-primary"
          >
            Upload <Upload />
          </button>
        </div>
      </div>
      <div className="filemgr-sidebar-body">
        <div className="pd-t-20 pd-b-10 pd-x-10">
          <label className="tx-sans tx-uppercase tx-medium tx-10 tx-spacing-1 tx-color-03 pd-l-10">
            Popis mapa
          </label>

          <nav className="nav nav-sidebar tx-13">
            {currentPath.length > 0 && (
              <a href="" className="nav-link" onClick={handleBackClicked}>
                <CornerLeftUp /> <span>Nazad</span>
              </a>
            )}
            {showNewDirectory && (
              <NewDirectory
                onSave={handleNewDirectorySave}
                directories={directories}
              />
            )}
            {directories.map((directory, index) => (
              <a
                href=""
                className="nav-link"
                key={`directory_${index}`}
                onClick={handleDirectoryClicked(directory)}
              >
                <Folder />{" "}
                <span>
                  {directory.substring(directory.lastIndexOf("/") + 1)}
                </span>
              </a>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default MediaManagerSidebar;
