import { Form, FormField } from "./types";

export enum FormFieldType {
  Text = "text",
  TextArea = "textarea",
  Checkbox = "checkbox",
  Radio = "radio",
  RangeCalendar = "range-calendar",
}

export const initialFormField: FormField = {
  title: "",
  type: FormFieldType.Text,
  value: "",
  options: [],
  required: false,
  id: "",
  dependencyId: "",
};

export const initialForm: Form = {
  id: -1,
  created_at: null,
  fields: [],
  name: "",
  recipients: "",
  updated_at: null,
};
