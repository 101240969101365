import { useMemo } from "react";
import { Button, Col, Row } from "react-bootstrap";
import {
  ArrowDown,
  ArrowLeft,
  ArrowRight,
  ArrowUp,
  Trash,
} from "react-feather";
import _ from "lodash";
import moment from "moment";

import { FormField as IFormField, FormFieldType } from "src/services/forms";
import { Switch } from "src/ui/components/Switch";
import { TextField } from "src/ui/components/TextField";

import { modeDown, moveLeft, moveRight, moveUp, deleteField } from "./helpers";
import { FormFieldProps } from "./types";

const smallButtonStyle = {
  height: "24px",
  width: "24px",
  padding: "3px 4px",
  margin: "-15px 5px 0px 0px",
};

const displayByType = (
  formField: IFormField,
  formValuesAndIds: { title: string; id: string }[],
  onOptionDependencyChange: (optionId: string, dependencyId: string) => void
) => {
  switch (formField.type) {
    case FormFieldType.Text:
      return <TextField disabled />;
    case FormFieldType.TextArea:
      return (
        <div className="form-group">
          <textarea
            className="form-control"
            style={{ height: "100px" }}
            disabled
          />
        </div>
      );
    case FormFieldType.RangeCalendar:
      console.log(_.chunk(formField.options, 2));
      const options = _.chunk(formField.options, 2)
        .map((option, index) => {
          const dateFrom = moment(option[0].value).format("DD.MM.YYYY.");
          const dateTo = moment(option[1].value).format("DD.MM.YYYY.");

          return dateFrom + " - " + dateTo;
        })
        .join("\n");

      return (
        <div className="form-group">
          <textarea
            className="form-control"
            style={{ height: "100px" }}
            disabled
            value={options}
          />
        </div>
      );
    case FormFieldType.Checkbox:
    case FormFieldType.Radio:
      return (
        <fieldset className="form-group row">
          <div className="col-sm-10">
            {formField.options.map((option) => (
              <div className="form-check" key={`${formField.id}-${option.id}`}>
                <input
                  className="form-check-input"
                  type={
                    formField.type === FormFieldType.Checkbox
                      ? "checkbox"
                      : "radio"
                  }
                  value="option1"
                  disabled
                />
                <label className="form-check-label">{option.value}</label>
                <select
                  style={{ marginLeft: "10px" }}
                  onChange={(e: any) =>
                    onOptionDependencyChange(option.id, e.target.value)
                  }
                  value={option.dependencyId}
                >
                  <option value="">-</option>
                  {formValuesAndIds.map((formValue) => {
                    if (formValue.id === formField.id) {
                      return null;
                    }

                    return (
                      <option key={formValue.id} value={formValue.id}>
                        {formValue.title}
                      </option>
                    );
                  })}
                </select>
              </div>
            ))}
          </div>
        </fieldset>
      );
    default:
      return <TextField disabled />;
  }
};

const FormField: React.FC<FormFieldProps> = ({ formFields, onChange }) => {
  const formValuesAndIds = useMemo(() => {
    return formFields.flatMap((field) => {
      return field.map((f) => {
        return {
          title: f.title,
          id: f.id!,
        };
      });
    });
  }, [formFields]);

  const handleUpClick = (rowIndex: number, fieldIndex: number) => () => {
    onChange(moveUp(formFields, rowIndex, fieldIndex));
  };

  const handleDownClick = (rowIndex: number, fieldIndex: number) => () => {
    onChange(modeDown(formFields, rowIndex, fieldIndex));
  };

  const handleLeftClick = (rowIndex: number, fieldIndex: number) => () => {
    onChange(moveLeft(formFields, rowIndex, fieldIndex));
  };

  const handleRightClick = (rowIndex: number, fieldIndex: number) => () => {
    onChange(moveRight(formFields, rowIndex, fieldIndex));
  };

  const handleDeleteClick = (rowIndex: number, fieldIndex: number) => () => {
    onChange(deleteField(formFields, rowIndex, fieldIndex));
  };

  const handleRequiredChange =
    (rowIndex: number, fieldIndex: number) => (checked: boolean) => {
      onChange([
        ...formFields.slice(0, rowIndex),
        [
          ...formFields[rowIndex].slice(0, fieldIndex),
          { ...formFields[rowIndex][fieldIndex], required: checked },
          ...formFields[rowIndex].slice(fieldIndex + 1),
        ],
        ...formFields.slice(rowIndex + 1),
      ]);
    };

  const handleOptionDependencyChange =
    (rowIndex: number, fieldIndex: number) =>
    (optionId: string, dependencyId: string) => {
      onChange([
        ...formFields.slice(0, rowIndex),
        [
          ...formFields[rowIndex].slice(0, fieldIndex),
          {
            ...formFields[rowIndex][fieldIndex],
            options: formFields[rowIndex][fieldIndex].options.map((option) => {
              if (option.id === optionId) {
                return {
                  ...option,
                  dependencyId: dependencyId,
                };
              }

              return option;
            }),
          },
          ...formFields[rowIndex].slice(fieldIndex + 1),
        ],
        ...formFields.slice(rowIndex + 1),
      ]);
    };

  return (
    <>
      {formFields.map((fields, fieldsIndex) => (
        <Row key={`fields_${fieldsIndex}`}>
          {fields.map((field, fieldIndex) => (
            <Col key={`field_${fieldIndex}`}>
              <div className="row pt-3" style={{ border: "1px dotted #ccc" }}>
                <div className="col-lg-12">
                  <label>{field.title}</label>
                  <div className="float-right">
                    <Switch
                      checked={field.required}
                      label="Obavezno polje"
                      onChange={handleRequiredChange(fieldsIndex, fieldIndex)}
                    />
                  </div>
                  {displayByType(
                    field,
                    formValuesAndIds,
                    handleOptionDependencyChange(fieldsIndex, fieldIndex)
                  )}
                </div>
                <div className="col-lg-12 mb-3">
                  {fields.length > 1 && fieldIndex < fields.length - 1 && (
                    <Button
                      variant="outline-secondary"
                      size="sm"
                      className="btn-xs float-right"
                      style={smallButtonStyle}
                      onClick={handleRightClick(fieldsIndex, fieldIndex)}
                    >
                      <ArrowRight />
                    </Button>
                  )}
                  {(fieldsIndex > 0 || fields.length > 1) && (
                    <Button
                      variant="outline-secondary"
                      size="sm"
                      className="btn-xs float-right"
                      style={smallButtonStyle}
                      onClick={handleUpClick(fieldsIndex, fieldIndex)}
                    >
                      <ArrowUp />
                    </Button>
                  )}
                  {(fieldsIndex < formFields.length - 1 ||
                    fields.length > 1) && (
                    <Button
                      variant="outline-secondary"
                      size="sm"
                      className="btn-xs float-right"
                      style={smallButtonStyle}
                      onClick={handleDownClick(fieldsIndex, fieldIndex)}
                    >
                      <ArrowDown />
                    </Button>
                  )}
                  {fields.length > 1 && fieldIndex > 0 && (
                    <Button
                      variant="outline-secondary"
                      size="sm"
                      className="btn-xs float-right"
                      style={smallButtonStyle}
                      onClick={handleLeftClick(fieldsIndex, fieldIndex)}
                    >
                      <ArrowLeft />
                    </Button>
                  )}
                  <Button
                    variant="outline-danger"
                    size="sm"
                    className="btn-xs float-right"
                    style={smallButtonStyle}
                    onClick={handleDeleteClick(fieldsIndex, fieldIndex)}
                  >
                    <Trash />
                  </Button>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      ))}
    </>
  );
};

export default FormField;
