import { Link } from "react-router-dom";
import { Column } from "react-table";

import { Form, FormFieldType } from "src/services/forms";

import { FormFieldOption } from "./types";

export const formColumns: Column<Form>[] = [
  {
    Header: "Naziv",
    accessor: "name",
    Cell: ({ row }) => {
      return (
        <Link to={`/forms/${row.original.id}/edit`}>{row.original.name}</Link>
      );
    },
  },
  {
    Header: "",
    accessor: "id",
    Cell: () => {
      return null;
    },
  },
];

export const formFieldOptions: FormFieldOption[] = [
  {
    title: "Tekst",
    hasOptions: false,
    type: FormFieldType.Text,
  },
  {
    title: "Tekst u više redova",
    hasOptions: false,
    type: FormFieldType.TextArea,
  },
  {
    title: "Kalendar",
    hasOptions: true,
    type: FormFieldType.RangeCalendar,
  },
  {
    title: "Checkbox",
    hasOptions: true,
    type: FormFieldType.Checkbox,
  },
  {
    title: "Radio",
    hasOptions: true,
    type: FormFieldType.Radio,
  },
];
