import React from "react";
import { Dropdown } from "react-bootstrap";
import { Download, MoreVertical, Trash, File } from "react-feather";
// import LazyLoad from "react-lazyload";
// import styled, { keyframes } from "styled-components";

import { deleteFile, downloadFile } from "src/services/storage";

import {
  getExtension,
  getFileName,
  getThumb,
  isImage,
} from "src/ui/components/MediaManager/helpers";
import { MediaManagerFileProps } from "./types";

// const loadingAnimation = keyframes`
//   0% {
//     background-color: #fff;
//   }
//   50% {
//     background-color: #ccc;
//   }
//   100% {
//     background-color: #fff;
//   }
// `;

// const Placeholder = styled.div`
//   position: absolute;
//   left: 0;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   z-index: 10000;
//   animation: ${loadingAnimation} 1s infinite;
// `;

const CustomToggle = React.forwardRef(
  ({ onClick }: { onClick: any }, ref: any) => (
    <a
      href=""
      ref={ref}
      className="dropdown-link"
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      <MoreVertical />
    </a>
  )
);

const MediaManagerFile: React.FC<MediaManagerFileProps> = ({
  file,
  multiple,
  onDelete,
  onSelect,
  setSelectedFiles,
  selectedFiles,
}) => {
  // const refPlaceholder = React.useRef(null);

  // const removePlaceholder = () => {
  //   (refPlaceholder.current as any).remove();
  // };

  const handleDownloadClick = async () => {
    await downloadFile(file);
  };

  const handleDeleteClick = async () => {
    // if (!confirm("Da li ste sigurni da želite obrisati datoteku?")) {
    //   return;
    // }

    await deleteFile(file);
    onDelete();
  };

  const toggleSelectedFile = () => {
    if (selectedFiles.includes(file)) {
      setSelectedFiles(selectedFiles.filter((f) => f !== file));
    } else {
      setSelectedFiles([...selectedFiles, file]);
    }
  };

  const handleSelectClick = (e: any) => {
    e.preventDefault();

    if (multiple) {
      toggleSelectedFile();
      return;
    }

    onSelect();
  };

  return (
    <div className="col-sm-6 col-lg-3 col-xl-3 mg-b-10">
      {/* <LazyLoad>
        <Placeholder ref={refPlaceholder} /> */}
      <div className="card card-file">
        {multiple && (
          <div className="dropdown-file left">
            <input
              className="form-check-input-mm"
              type="checkbox"
              value=""
              checked={selectedFiles.includes(file)}
              onChange={toggleSelectedFile}
            />
          </div>
        )}
        <div className="dropdown-file">
          <Dropdown>
            <Dropdown.Toggle as={CustomToggle} />
            <Dropdown.Menu>
              <Dropdown.Item onClick={handleDownloadClick}>
                <Download />
                Preuzmi
              </Dropdown.Item>
              <Dropdown.Item onClick={handleDeleteClick}>
                <Trash /> Obriši
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div>
          {isImage(file) ? (
            <a href="#insert" onClick={handleSelectClick}>
              <img
                alt=""
                src={getThumb(file)}
                className="filemgr-image-thumb"
                // loading="lazy"
                // onLoad={removePlaceholder}
                // onError={removePlaceholder}
              />
            </a>
          ) : (
            <div className="card-file-thumb tx-danger">
              <a href="#insert" onClick={handleSelectClick}>
                <File />
              </a>
            </div>
          )}
        </div>
        <div className="card-body">
          <h6>
            <a
              href="#insert"
              className="link-02"
              style={{ overflow: "hidden" }}
              onClick={handleSelectClick}
            >
              {getFileName(file)}
            </a>
          </h6>
          <p>{getExtension(file)}</p>
          {/* <span>1000.45kb</span> */}
        </div>
      </div>
      {/* </LazyLoad> */}
    </div>
  );
};

export default MediaManagerFile;
