import React from "react";
import { Maximize2, Move, Trash2 } from "react-feather";

import { getFileFullPath, getThumb } from "src/ui/components/MediaManager";

export const GalleryImage = ({ image, onDelete }) => {
  return (
    <figure
      className="pos-relative mg-b-0 wd-lg-20p mg-r-10 mg-b-10"
      style={{ width: "265px", height: "176px" }}
    >
      <img src={getThumb(image)} className="img-fit-cover" alt="" />
      <figcaption className="pos-absolute b-0 l-0 wd-100p pd-20 d-flex justify-content-center">
        <div className="btn-group">
          <a href="" className="btn btn-dark btn-icon">
            <Move style={{ height: "12px", marginTop: "-2px" }} />
          </a>
          <a
            href={getFileFullPath(image)}
            target="_blank"
            rel="noreferrer"
            className="btn btn-dark btn-icon"
          >
            <i data-feather="maximize-2"></i>
            <Maximize2 style={{ height: "12px", marginTop: "-2px" }} />
          </a>
          <a href="" className="btn btn-dark btn-icon" onClick={onDelete}>
            <i data-feather="trash-2"></i>
            <Trash2 style={{ height: "12px", marginTop: "-2px" }} />
          </a>
        </div>
      </figcaption>
    </figure>
  );
};
