import React, { useEffect, useState } from "react";
import { Button, Card, Col } from "react-bootstrap";
import { Download, Save, Trash } from "react-feather";
import { useHistory, useParams } from "react-router";
import { useToasts } from "react-toast-notifications";
import { v4 as uuidv4 } from "uuid";

import {
  initialForm,
  Form,
  initialFormField,
  FormFieldType,
  FormField,
  getForm,
  putForm,
  postForm,
  FormFieldOption,
  getFormResultsXlsxStream,
} from "src/services/forms";
import { TextField } from "src/ui/components/TextField";
import { validateYup } from "src/services/validations";

import { FormBuilderProps } from "./types";
import { FormFields, FormFieldAdd } from "./components";
import { formFieldOptions } from "./consts";
import { formBuilderValidation } from "./validations";

const FormBuilder: React.FC<FormBuilderProps> = () => {
  const { id: activeId } = useParams() as { id?: string };
  const history = useHistory();
  const { addToast } = useToasts();
  const [form, setForm] = useState<Form>(initialForm);
  const [errors, setErrors] = useState<any>({});

  useEffect(() => {
    if (activeId) {
      (async () => {
        const resForm = await getForm(parseInt(activeId, 10));
        setForm(resForm);
      })();
    }
  }, [activeId]);

  const handleTextFieldChange = (e: any) =>
    setForm((old) => ({ ...old, [e.target.name]: e.target.value }));

  const handleAddField = (
    type: FormFieldType,
    title: string,
    options: FormFieldOption[]
  ) => {
    setForm((old) => ({
      ...old,
      fields: [
        ...old.fields,
        [{ ...initialFormField, type, title, id: uuidv4(), options }],
      ],
    }));
  };

  const handleFormFieldsChange = (formFields: FormField[][]) => {
    setForm((old) => ({ ...old, fields: formFields }));
  };

  const handleSaveClicked = async () => {
    if (!validateYup(form, formBuilderValidation, setErrors)) {
      return;
    }

    let msg = "";

    if (activeId) {
      await putForm(form);
      msg = "Obrazac je uređen";
    } else {
      await postForm(form);
      msg = "Dodan je novi obrazac";
    }

    history.push("/forms");

    addToast(msg, { appearance: "success" });
  };

  const handleDownloadXlsxClick = async () => {
    try {
      const res = await getFormResultsXlsxStream(form.id);
      const blob = new Blob([res], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${form.name}.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch {
      addToast("Greška prilikom preuzimanja podataka", {
        appearance: "error",
      });
    }
  };

  return (
    <>
      <Col xs={4}>
        <Card>
          <Card.Header>
            <Button size="sm" variant="primary" onClick={handleSaveClicked}>
              <Save /> Spremi
            </Button>
            {form.id > 0 && (
              <>
                <Button size="sm" variant="outline-danger" className="mg-l-4">
                  <Trash />
                  Obriši
                </Button>
                <Button
                  size="sm"
                  variant="outline-primary"
                  className="mg-l-4"
                  onClick={handleDownloadXlsxClick}
                >
                  <Download />
                  XLSX
                </Button>
              </>
            )}
          </Card.Header>
          <Card.Body>
            <TextField
              label="Naziv obrasca"
              onChange={handleTextFieldChange}
              name="name"
              value={form.name}
              required
              hasError={!!errors.name}
            />
            <TextField
              label="E-mail primatelja"
              onChange={handleTextFieldChange}
              name="recipients"
              value={form.recipients}
              required
              hasError={!!errors.recipients}
            />
          </Card.Body>
        </Card>

        <Card style={{ marginTop: "10px" }}>
          <Card.Header as="h6">Elementi za dodavanje</Card.Header>
          <Card.Body>
            {formFieldOptions.map((option, key) => (
              <FormFieldAdd
                formFieldOption={option}
                key={`option_${key}`}
                onAdd={handleAddField}
              />
            ))}
          </Card.Body>
        </Card>
      </Col>
      <Col xs={8}>
        <Card>
          <Card.Header as="h6">Prikaz obrasca</Card.Header>
          <Card.Body>
            <FormFields
              formFields={form.fields}
              onChange={handleFormFieldsChange}
            />
          </Card.Body>
        </Card>
      </Col>
    </>
  );
};

export default FormBuilder;
