import React, { useEffect, useState } from "react";
import { Edit, List, Navigation, FileText, Image } from "react-feather";
import { Link } from "react-router-dom";
import _ from "lodash";
import moment from "moment";

import { getArticles } from "src/services/articles";
import { MediaManager } from "src/ui/components/MediaManager";

const HomePage = () => {
  const [groupedArticles, setGroupedArticles] = useState({});
  const [dates, setDates] = useState([]);
  const [showFiles, setShowFiles] = useState(false);

  useEffect(() => {
    (async () => {
      const resArticles = await getArticles(1, 30, { onlyActive: "true" });

      setDates(
        _.uniq(
          resArticles.data.map((a) =>
            moment(a.active_from).format("DD.MM.YYYY.")
          )
        )
      );
      setGroupedArticles(
        _.groupBy(resArticles.data, (article) => {
          return moment(article.active_from).format("DD.MM.YYYY.");
        })
      );
    })();
  }, []);

  const handleEditFilesClick = () => {
    setShowFiles(true);
  };

  return (
    <>
      <div className="col-lg-12 col-xl-12">
        <h2>Nadzorna ploča</h2>
        <div className="row pd-15">
          <Link to={`/articles/create`}>
            <div className="card bd-default mg-r-20 mt-1 shadow-base">
              <div className="card-body pd-lg-25">
                <div className="btn-group mg-t-20 mg-sm-t-0">
                  <div className="mg-r-10">
                    <FileText size={48} color="#000" />
                  </div>
                  <div
                    style={{ fontSize: "20px", color: "#000" }}
                    className="mg-t-10"
                  >
                    Dodaj novi članak
                  </div>
                </div>
              </div>
            </div>
          </Link>

          <Link to={`/articles`}>
            <div className="card bd-default mg-r-20 mt-1 shadow-base">
              <div className="card-body pd-lg-25">
                <div className="btn-group mg-t-20 mg-sm-t-0">
                  <div className="mg-r-10">
                    <List size={48} color="#000" />
                  </div>
                  <div
                    style={{ fontSize: "20px", color: "#000" }}
                    className="mg-t-10"
                  >
                    Pregled članaka
                  </div>
                </div>
              </div>
            </div>
          </Link>

          <Link to={`/categories`}>
            <div className="card bd-default mg-r-20 mt-1 shadow-base">
              <div className="card-body pd-lg-25">
                <div className="btn-group mg-t-20 mg-sm-t-0">
                  <div className="mg-r-10">
                    <Edit size={48} color="#000" />
                  </div>
                  <div
                    style={{ fontSize: "20px", color: "#000" }}
                    className="mg-t-10"
                  >
                    Pregled kategorija
                  </div>
                </div>
              </div>
            </div>
          </Link>

          <Link to={`/blocks/navigation`}>
            <div
              className="card bd-default mg-r-20 mt-1 shadow-base"
              style={{ width: "220px" }}
            >
              <div className="card-body pd-lg-25">
                <div className="btn-group mg-t-20 mg-sm-t-0">
                  <div className="mg-r-10">
                    <Navigation size={48} color="#000" />
                  </div>
                  <div
                    style={{ fontSize: "20px", color: "#000" }}
                    className="mg-t-10"
                  >
                    Navigacija
                  </div>
                </div>
              </div>
            </div>
          </Link>
          
          <Link onClick={handleEditFilesClick}>
            <div
              className="card bd-default mg-r-20 mt-1 shadow-base"
              style={{ width: "250px" }}
            >
              <div className="card-body pd-lg-25">
                <div className="btn-group mg-t-20 mg-sm-t-0">
                  <div className="mg-r-10">
                    <Image size={48} color="#000" />
                  </div>
                  <div
                    style={{ fontSize: "20px", color: "#000" }}
                    className="mg-t-10"
                  >
                    Media Manager
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
      </div>

      <div className="col-lg-8 mg-t-10 mg-b-30">
        <div className="card">
          <div className="card-header">
            <h6 className="mg-b-0">Zadnjih 30 objava</h6>
          </div>
          <div className="card-body pd-10">
            <div className="timeline-group tx-13">
              {dates.map((date) => (
                <React.Fragment key={date}>
                  <div className="timeline-label">{date}</div>
                  {(groupedArticles[date] || []).map((article) => (
                    <div
                      className="timeline-item"
                      key={`article_${article.id}`}
                    >
                      <div className="timeline-time">
                        {moment(article.active_from).format("HH:mm")}
                      </div>
                      <div className="timeline-body">
                        <h6 className="mg-b-0">
                          <Link to={`/articles/${article.id}/edit`}>
                            {article.title}
                          </Link>
                          <span className="ml-2">|</span>
                          <span className="ml-2">
                            Kategorije:{" "}
                            {article.categories.map((c) => c.name).join(", ")}
                          </span>
                          {/* <span className="ml-2">|</span>
                          <span className="ml-2">Čitano: 0 puta</span> */}
                        </h6>
                      </div>
                    </div>
                  ))}
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>

      <MediaManager
        open={showFiles}
        onClose={() => setShowFiles(false)}
        onSelect={() => {}}
      />
    </>
  );
};

export default HomePage;
