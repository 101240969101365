import { apiCall, transformToPagination2 } from "../api";
import { transformToForm, transformToRequestForm } from "./transformations";
import { Form } from "./types";

export const getForms = async (page: number, pageSize: number) => {
  const res = await apiCall.get(
    `/api/v1/forms?page=${page}&pageSize=${pageSize}`
  );

  return transformToPagination2(res.data, transformToForm);
};

export const getForm = async (id: number) => {
  const res = await apiCall.get(`/api/v1/forms/${id}`);

  return transformToForm(res.data);
};

export const postForm = async (form: Form) => {
  const res = await apiCall.post(`/api/v1/forms`, transformToRequestForm(form));

  return transformToForm(res.data);
};

export const putForm = async (form: Form) => {
  const res = await apiCall.put(
    `/api/v1/forms/${form.id}`,
    transformToRequestForm(form)
  );

  return transformToForm(res.data);
};

export const deleteForm = async (id: number) => {
  await apiCall.delete(`/api/v1/forms/${id}`);
};

export const getFormResultsXlsxStream = async (id: number) => {
  const res = await apiCall.get(`/api/v1/form/export/${id}`, {
    responseType: "arraybuffer",
  });

  return res.data;
};
