import { API_URL } from "src/services/api";

export const getExtension = (file: string) =>
  file.substring(file.indexOf(".") + 1);

export const getFileName = (file: string) =>
  file.substring(file.lastIndexOf("/") + 1).replace(/\.[^/.]+$/, "");

export const isImage = (file: string) =>
  ["jpg", "jpeg", "png", "gif"].includes(getExtension(file).toLowerCase());

export const getThumb = (file: string) => {
  const extension = getExtension(file).toLowerCase();

  switch (extension) {
    case "jpg":
    case "jpeg":
    case "png":
      const fileParts = file.split("/");
      const fileName = fileParts.pop();

      return `${API_URL}/storage/${fileParts.join("/")}/__thumb/${fileName}`;
    case "gif":
      return API_URL + "/render-image/storage/" + file + "?w=220&h=140&q=70";
    case "pdf":
      return "";
    default:
      return "";
  }
};

export const getFileFullPath = (file: string) => `${API_URL}/storage/${file}`;
