import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";

import {
  ArticlesPage,
  ArticlesForm,
  HomePage,
  LoginPage,
  UsersPage,
  UsersForm,
  GroupsPage,
  GroupsForm,
  CategoriesForm,
  CategoriesPage,
  BlockSlides,
  BlockSlideForm,
  Navigation,
  BlockPinnedNews,
  BlockPinnedNewsForm,
  BlockPinnedItems,
  BlockPinnedItemsForm,
  BlockPinnedQuotes,
  BlockPinnedQuotesForm,
  BlockFooterLink,
  BlockFooterLinkForm,
  BlockAboutUs,
  BlockAboutUsForm,
  BlockTopButton,
  BlockTopButtonForm,
  BlockBlogSidebar,
  BlockBlogSidebarForm,
  FormsPage,
  FormBuilder,
  BlockBranches,
  BlockBranchesForm
} from "src/ui/pages";
import { UserGroupsPage, UserGroupForm } from "src/ui/pages/UserGroups";

import { ActionCreators } from "../redux/actions";
import { isUserLogedIn } from "../redux/selectors";

export default function AppRouter() {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const isLogedIn = useSelector(isUserLogedIn);

  useEffect(() => {
    if (!isLogedIn && location.pathname !== "/login") {
      history.push("/login");
    }

    if (isLogedIn && location.pathname !== "/login") {
      dispatch(ActionCreators.loginCheck());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogedIn]);

  return (
    <Switch>
      <Route path="/login">
        <LoginPage />
      </Route>
      <Route exact path="/articles">
        <ArticlesPage />
      </Route>
      <Route exact path="/articles/create">
        <ArticlesForm />
      </Route>
      <Route exact path="/articles/:id/edit">
        <ArticlesForm />
      </Route>
      <Route exact path="/categories">
        <CategoriesPage />
      </Route>
      <Route exact path="/categories/create">
        <CategoriesForm />
      </Route>
      <Route exact path="/categories/:id/edit">
        <CategoriesForm />
      </Route>
      <Route exact path="/blocks/slides">
        <BlockSlides />
      </Route>
      <Route exact path="/blocks/slides/create">
        <BlockSlideForm />
      </Route>
      <Route exact path="/blocks/slides/:id/edit">
        <BlockSlideForm />
      </Route>
      <Route exact path="/blocks/navigation">
        <Navigation />
      </Route>
      <Route exact path="/blocks/pinned-news">
        <BlockPinnedNews />
      </Route>
      <Route exact path="/blocks/pinned-news/:id/edit">
        <BlockPinnedNewsForm />
      </Route>
      <Route exact path="/blocks/pinned-news/create">
        <BlockPinnedNewsForm />
      </Route>
      <Route exact path="/blocks/pinned-items">
        <BlockPinnedItems />
      </Route>
      <Route exact path="/blocks/pinned-items/create">
        <BlockPinnedItemsForm />
      </Route>
      <Route exact path="/blocks/pinned-items/:id/edit">
        <BlockPinnedItemsForm />
      </Route>
      <Route exact path="/blocks/pinned-quotes">
        <BlockPinnedQuotes />
      </Route>
      <Route exact path="/blocks/pinned-quotes/create">
        <BlockPinnedQuotesForm />
      </Route>
      <Route exact path="/blocks/pinned-quotes/:id/edit">
        <BlockPinnedQuotesForm />
      </Route>
      <Route exact path="/blocks/footer-link">
        <BlockFooterLink />
      </Route>
      <Route exact path="/blocks/footer-link/create">
        <BlockFooterLinkForm />
      </Route>
      <Route exact path="/blocks/footer-link/:id/edit">
        <BlockFooterLinkForm />
      </Route>
      <Route exact path="/blocks/about-us-link">
        <BlockAboutUs />
      </Route>
      <Route exact path="/blocks/about-us-link/create">
        <BlockAboutUsForm />
      </Route>
      <Route exact path="/blocks/about-us-link/:id/edit">
        <BlockAboutUsForm />
      </Route>
      <Route exact path="/blocks/top-button">
        <BlockTopButton />
      </Route>
      <Route exact path="/blocks/top-button/create">
        <BlockTopButtonForm />
      </Route>
      <Route exact path="/blocks/top-button/:id/edit">
        <BlockTopButtonForm />
      </Route>
      <Route exact path="/blocks/blog-sidebar">
        <BlockBlogSidebar />
      </Route>
      <Route exact path="/blocks/blog-sidebar/create">
        <BlockBlogSidebarForm />
      </Route>
      <Route exact path="/blocks/blog-sidebar/:id/edit">
        <BlockBlogSidebarForm />
      </Route>
      <Route exact path="/blocks/branches">
        <BlockBranches />
      </Route>
      <Route exact path="/blocks/branches/create">
        <BlockBranchesForm />
      </Route>
      <Route exact path="/blocks/branches/:id/edit">
        <BlockBranchesForm />
      </Route>
      <Route exact path="/users">
        <UsersPage />
      </Route>
      <Route exact path="/users/:id/edit">
        <UsersForm />
      </Route>
      <Route exact path="/users/create">
        <UsersForm />
      </Route>
      <Route exact path="/user-groups">
        <UserGroupsPage />
      </Route>
      <Route exact path="/user-groups/create">
        <UserGroupForm />
      </Route>
      <Route exact path="/user-groups/:id/edit">
        <UserGroupForm />
      </Route>
      <Route exact path="/groups">
        <GroupsPage />
      </Route>
      <Route exact path="/groups/:id/edit">
        <GroupsForm />
      </Route>
      <Route exact path="/groups/create">
        <GroupsForm />
      </Route>
      <Route exact path="/forms">
        <FormsPage />
      </Route>
      <Route exact path="/forms/:id/edit">
        <FormBuilder />
      </Route>
      <Route path="/forms/create">
        <FormBuilder />
      </Route>
      <Route exact path="/">
        <HomePage />
      </Route>
    </Switch>
  );
}
