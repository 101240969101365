import { useEffect, useState, useRef, useMemo } from "react";
import { Search } from "react-feather";
import { useDebounce } from "use-debounce/lib";

import { initialStorage } from "src/services/storage/consts";
import { addFile, getFiles } from "src/services/storage";

import { MediaManagerContent, MediaManagerSidebar } from "./components";
import { MediaManagerProps, UploadStatus } from "./types";

const MediaManager: React.FC<MediaManagerProps> = ({
  open,
  multiple = false,
  onClose,
  onSelect,
}) => {
  const [storage, setStorage] = useState(initialStorage);
  const [currentPath, setCurrentPath] = useState<string[]>([]);
  const [search, setSearch] = useState("");
  const [, setIsLoading] = useState(false);
  const [status, setStatus] = useState<UploadStatus | null>(null);
  const [activeFile, setActiveFile] = useState<File | null>(null);
  const [selectedFiles, setSelectedFiles] = useState<string[]>([]);

  const debouncedSearch = useDebounce(search, 500);

  const newFileRef = useRef<any>(null);

  useEffect(() => {
    if (!open) {
      setStorage(initialStorage);
      setCurrentPath([]);
      setSelectedFiles([]);
      return;
    }

    (async () => {
      fetchStorage([]);
    })();
  }, [open]);

  useEffect(() => {
    if (open) {
      (async () => fetchStorage(currentPath))();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPath]);

  const filteredFiles = useMemo(() => {
    if (!debouncedSearch[0]) {
      return storage.files;
    }

    return storage.files.filter((f) => {
      const fileName = f.split("/").pop();

      return fileName!.toLowerCase().includes(debouncedSearch[0].toLowerCase());
    });
  }, [debouncedSearch, storage.files]);

  const fetchStorage = async (path: string[]) => {
    setIsLoading(true);

    try {
      const resStorage = await getFiles(path.join("/"));
      setStorage(resStorage);
    } catch {}

    setIsLoading(false);
  };

  const setFiles = (newFiles: string[]) => {
    setStorage({ ...storage, files: newFiles });
  };

  const setDirectories = (newDirs: string[]) => {
    setStorage({ ...storage, directories: newDirs });
  };

  const handleNewFileClick = () => {
    newFileRef.current!.click();
  };

  const handleCloseClick = (e: any) => {
    e.preventDefault();
    onClose();
  };

  const handleNewFileChange = async (e: any) => {
    setIsLoading(true);

    const files = e.target.files;

    for (let i = 0; i < files.length; i++) {
      setStatus(UploadStatus.PENDING);
      try {
        setActiveFile(files[i]);
        await addFile(files[i], currentPath.join("/"));
        setStatus(UploadStatus.SUCCESS);
      } catch (e) {
        setStatus(UploadStatus.FAILED);
      }
    }

    fetchStorage(currentPath);
  };

  if (!open) {
    return null;
  }

  return (
    <>
      <div
        className="light-modal"
        id="mediamanager"
        role="dialog"
        aria-labelledby="light-modal-label"
        aria-hidden="false"
        style={{ visibility: open ? "visible" : "hidden" }}
      >
        <div className="light-modal-content xl animated bounceIn">
          <a
            href="#"
            onClick={handleCloseClick}
            className="light-modal-close-icon"
            aria-label="close"
          >
            ×
          </a>
          <div className="light-modal-body">
            <div className="light-modal-title">Media manager</div>
            <div className="col-lg-12">
              <div className="row">
                <div className="content ht-100v pd-0 wd-100p">
                  <div className="content-header filemgr-search-container">
                    <div className="content-search wd-100p filemgr-search-box">
                      <Search />
                      <input
                        type="search"
                        className="form-control"
                        placeholder="Pretraži datoteke"
                        onChange={(e: any) => setSearch(e.target.value)}
                      />
                    </div>
                  </div>
                  <div
                    className="content-body pd-0"
                    style={{ overflowY: "visible" }}
                  >
                    <div className="filemgr-wrapper filemgr-wrapper-two">
                      <MediaManagerSidebar
                        directories={storage.directories}
                        setDirectories={setDirectories}
                        currentPath={currentPath}
                        setCurrentPath={setCurrentPath}
                        onNewFileClick={handleNewFileClick}
                      />
                      <MediaManagerContent
                        files={filteredFiles}
                        multiple={multiple}
                        setFiles={setFiles}
                        onFileSelect={onSelect}
                        status={status}
                        setStatus={setStatus}
                        activeFile={activeFile}
                        selectedFiles={selectedFiles}
                        setSelectedFiles={setSelectedFiles}
                      />
                      <input
                        type="file"
                        style={{ display: "none" }}
                        ref={newFileRef}
                        onChange={handleNewFileChange}
                        multiple
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MediaManager;
