import { apiCall } from "../api";
import { Storage } from "./types";

const download = (path: any, filename: string) => {
  const anchor = document.createElement("a");
  anchor.href = path;
  anchor.download = filename;
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
};

export const getFiles = async (path: string): Promise<Storage> => {
  const res = await apiCall.get(`/api/v1/storage/get-files?path=${path}`);

  return res.data;
};

export const deleteFile = async (file: string): Promise<void> => {
  try {
    await apiCall.post(`/api/v1/storage/delete-file`, { file });
  } catch (err) {
    console.error(err);
  }
};

export const downloadFile = async (file: string): Promise<any> => {
  try {
    const res = await apiCall.post(
      `/api/v1/storage/download-file`,
      {
        file,
      },
      { responseType: "blob" }
    );

    const blob = new Blob([res.data], { type: res.headers["content-type"] });
    const url = URL.createObjectURL(blob);

    const fileName = file.split("/").pop();

    download(url, fileName!);
  } catch (err) {
    console.error(err);
  }
};

export const createDirectory = async (name: string): Promise<void> => {
  try {
    await apiCall.post("/api/v1/storage/create-directory", { path: name });
  } catch (err) {
    console.error(err);
  }
};

export const addFile = async (file: File, path: string): Promise<void> => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("path", path);

    await apiCall.post("/api/v1/storage/add-file", formData);
  } catch (err) {
    throw err;
  }
};
