export interface MediaManagerProps {
  open: boolean;
  multiple?: boolean;
  onClose: () => void;
  onSelect: (file: string | string[]) => void;
}

export enum UploadStatus {
  SUCCESS,
  FAILED,
  PENDING,
}
